import { render, staticRenderFns } from "./ZaloList.vue?vue&type=template&id=b093a6f0&scoped=true&"
import script from "./ZaloList.vue?vue&type=script&lang=js&"
export * from "./ZaloList.vue?vue&type=script&lang=js&"
import style0 from "./ZaloList.vue?vue&type=style&index=0&id=b093a6f0&lang=scss&scoped=true&"
import style1 from "./ZaloList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b093a6f0",
  null
  
)

export default component.exports