import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchZalos(ctx, params) {
      return useJwt.getZalos(params).then(response => response);
    },
    createZalo(ctx, data) {
      return useJwt.createZalo(data).then(response => response);
    },
    updateZalo(ctx, data) {
      return useJwt.updateZalo(data).then(response => response);
    },
    getZaloDetail(ctx, id) {
      return useJwt.getZaloDetail(id).then(response => response);
    },
    deleteZalos(ctx, data) {
      return useJwt.deleteZalos(data).then(response => response);
    },
    getZaloLinkURL(ctx) {
      return useJwt.getZaloLinkURL().then(response => response);
    },
    linkOA(ctx, data) {
      return useJwt.linkZaloOA(data).then(response => response);
    },
  },
};
